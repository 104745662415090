import React from "react";
import Login from "../Components/admin-dashboard/Login";

const Admin = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default Admin;
