import React, { Fragment } from "react";
import Login from "../Components/admin-dashboard/Login";
import CarList from "../Components/List/CarList";

const List = () => {
  return (
    <div>
      {/* <CarList /> */}
      <Login />
    </div>
  );
};

export default List;
